<template>
    <div class="row">
      <div class="col-md-12">
        <form @submit.prevent="filterResult(null)">
          <div class="row py-3 px-4" id="filter-card">
            <div class="col-lg-12 col-xl-12 px-0">
              <div class="row align-items-end py-0 my-0 mx-0">
                <div class="col-lg-11 col-md-11 col-sm-12 my-2 row px-0 mx-0">
                  <input-filter
                      :helper-text="$t('general.search')"
                      title="general.search"
                      :placeholder="$t('general.search_filter')"
                      :model.sync="search"
                  ></input-filter>
                </div>
                <div
                    class="col-xl-1 my-lg-1 col-md-2 col-sm-12 mt-3 d-flex align-items-center justify-content-end m-0 px-0 row"
                    style="margin: 0 !important;">
                  <div class="col-12 pr-0">
                    <button @click="filterResult(null)"
                            class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1">{{
                        $t('general.filter')
                      }}
                    </button>
                  </div>
                  <div class="col-12 pr-0">
                    <button @click="resetFilters"
                            class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1">
                      {{ $t('general.clear') }}
                    </button>
                  </div>
                </div>
                <div
                    class="col-xl-1 my-lg-1 col-md-2 col-sm-12 mt-3 d-flex align-items-center justify-content-end m-0 px-0 row"
                >
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
  
      <div class="col-md-12">
        <dashboard-box :title="$t('general.accessories')">
          <template v-slot:preview>
            <data-table
                tableVariant="''"
                :hover="false"
                :outlined="false"
                :striped="false"
                :no-border-collapse="true"
                :borderless="true"
                :bordered="false"
                @onChangeStatus="onChangeStatus"
                @onClickEdit="onClickEdit"
                @hitBottom="onButtonHit"
                :items="itemsProxy"
                :fields="fields"
                :page.sync="page"
                :perPage.sync="per_page"
            ></data-table>
            <div class="d-flex justify-content-end items-end float-right mt-0 ">
              <div class="w-60px h-60px " v-if="isUserGranted('InvoiceCustomApprovalType', ['create'],false,true)"
                   @click="$router.push({name: 'management.invoice_custom_approval_type.create'})">
                <span v-html="getIconByKey('icons.waybill.waybill_entry', {
                    class: 'w-60px h-60px d-inline-block object-fill cursor-pointer'
                  })">
                  </span>
              </div>
            </div>
          </template>
        </dashboard-box>
      </div>
    </div>
  </template>
  
  <script>
  import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
  import moment from "moment";
  
  // COMPONENTS
  import DashboardBox from "@/assets/components/DashboardBox";
  import DataTable from "@/assets/components/dataTable/DataTable";
  import InputFilter from "@/assets/components/filters/InputFilter";
  import SelectFilter from "@/assets/components/filters/SelectFilter";
  import DateFilter from "@/assets/components/filters/DateFilter";
  import baseModule, {
    BASE_URL,
    ERROR,
    SET_ITEMS,
    ITEMS,
    LOADING,
    MODULE_NAME,
    DELETE_ITEM,
    SUCCESS,
    HANDLE_INFINITE_SCROLL,
    CHANGE_STATUS_BY_ID
  } from "@/core/services/store/invoice-custom-approval-type/invoice_custom_approval_type.module";
  import store from "@/core/services/store";
  
  import {LARAVEL_DATE_FORMAT, MOMENT_SYSTEM_DATE_FORMAT} from "@/core/config/constant";
  import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
  
  const _MODULE_NAME = MODULE_NAME;
  
  export default {
    name: "InvoiceCustomApprovalTypeIndex",
    beforeCreate() {
      function registerStoreModule(moduleName, storeModule) {
        if (!(store && store.state && store.state[moduleName])) {
          store.registerModule(moduleName, storeModule)
        }
      }
  
      registerStoreModule(_MODULE_NAME, baseModule)
    },
    components: {
      CustomMultiSelect,
      SelectFilter,
      InputFilter,
      DataTable,
      DashboardBox,
      DateFilter
    },
    computed: {
      error() {
        return store.getters[_MODULE_NAME + '/' + ERROR];
      },
      loading() {
        return store.getters[_MODULE_NAME + '/' + LOADING];
      },
      success() {
        return store.getters[_MODULE_NAME + '/' + SUCCESS];
      },
      items() {
        return store.getters[_MODULE_NAME + '/' + ITEMS];
      },
      filters() {
        return {
          per_page: this.per_page,
          page: this.page,
          search: this.search,
          language_id: this.selectedLanguageId,
          expense_category:this.selectedExpenseCategory
        };
      },
      itemsProxy: {
        get() {
          let results = [];
          let items = this.items;
          if (items && items.data) {
            items.data.forEach(item => {
              results.push({
                id: item.id,
                name: item.translation.name,
                isActive: +item.status === 1,
                createdAt: (item.created_at) ? moment(item.created_at, LARAVEL_DATE_FORMAT).format(MOMENT_SYSTEM_DATE_FORMAT) : '',
              })
            })
          }
          return results
        },
        set(value) {
        },
      },
      expense_category(){
        let expanse_category_ids={1:this.$t('expense.expenses'),2:this.$t('expense.general_card')}
        return expanse_category_ids;
      },
    },
    data() {
      return {
        search: '',
        page: 1,
        per_page: 25,
        fields: [
          {
            key: 'id',
            label: this.$t('general.id'),
            sortable: true,
            class: 'text-center border border-top-0 border-right-0 font-size-lg '
          },
          {
            key: 'name',
            label: this.$t('general.name'),
            sortable: true,
            class: 'text-center border border-top-0 border-right-0 font-size-lg w-30'
          },
          {
            key: 'status',
            label: this.$t('general.status'),
            sortable: false,
            class: 'text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg '
          },
          {
            key: 'only_update',
            label: this.$t('general.actions'),
            sortable: false,
            class: 'text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg w-25'
          },
        ],
        selectedLanguageId: null,
        languages: [],
        selectedExpenseCategory:null,
      };
    },
    methods: {
      onButtonHit($state) {
        if (this.loading) {
          return;
        }
        this.state = $state;
        this.$store.dispatch(_MODULE_NAME + '/' + HANDLE_INFINITE_SCROLL, {
          filters: this.filters,
          url: BASE_URL,
          '$state': $state,
        }).then(result => {
          if (result.status && $state) {
            $state.loaded();
          } else {
            if ('end' in result && $state) {
              $state.complete();
            }
          }
        })
      },
      filterResult() {
        this.page = 1;
        this.filters.page = 1;
        let state = this.state;
        this.onButtonHit();
        setTimeout(() => {
          if (state) {
            state.reset();
          }
        }, 100)
      },
      resetFilters() {
        this.search = '';
      },
      onClickEdit(id) {
        if(this.isUserGranted('InvoiceCustomApprovalType', ['update'],true,true)){
          this.$router.push({name: 'management.invoice_custom_approval_type.edit', params: {id: id}})
        }
        
      },
      onChangeStatus(id) {
        if(this.isUserGranted('InvoiceCustomApprovalType', ['update'],true,true)){
            let payload = {
            url: 'api/invoice-custom-approval-types/' + id + '/change/status',
            id: id,
          }
          this.$store.dispatch(_MODULE_NAME + '/' + CHANGE_STATUS_BY_ID, payload)
        }
        
      },
    },
    mounted() {
      this.showSuccessNotification();
      this.showErrorNotification();
      this.$store.dispatch(SET_BREADCRUMB, [
        {title: this.$t("general.dashboard"), route: "dashboard"},
        {title: this.$t("menu.management.invoice_custom_approval_types")}
      ])
      this.$store.commit(_MODULE_NAME + '/' + SET_ITEMS, {});
  
      this.languages = this.convertArrayToObjectByKey((this.systemLanguages), 'id', 'name');
  
      this.selectedLanguageId = this.activeLanguageId;
    },
    watch: {
      success: function (newValue, oldValue) {
        if (newValue) {
          let self = this;
          setTimeout(() => {
            self.sweetAlertSuccess(this.$t('general.successfully_saved'));
          }, 1000);
        }
      }
    }
  }
  </script>
  
  <style>
  *:focus {
    outline: none;
  }
  
  #dashboard-card-body {
    padding-bottom: 5px !important;
  }
  </style>
  